<template>
  <div class="jobhunter">
    <div class="blck_left">
      <router-link to="/list" style="color: #fff">
        <van-icon name="arrow-left" />
        <span class="blcktitile">返回</span>
      </router-link>
    </div>
    <div class="top" v-if="indexshow">
      <img src="../../assets/qiye.png" alt="" style="width: 100%" />
      <!-- <div class="names">{{list.Name}}</div> -->
    </div>
    <div v-if="indexshow">
      <!-- <van-cell title="在线简历" is-link />
      <van-cell title="投递列表" is-link /> -->
      <div class="center">
        <van-cell title="职位管理" to="/list/firmlogin/jobList" is-link />
        <van-cell
          title="发布职位"
          to="/list/firmlogin/jobList/position"
          is-link
        />
        <van-cell title="已投递的简历" to="/list/company/apply" is-link />
        <van-cell title="人才库" to="/list/company/jobname" is-link />
        <van-cell title="候选人筛选" to="/list/company/candidates" is-link />
        <van-cell title="企业信息" to="/list/company/Info" is-link />
        <van-cell title="修改密码" @click="change" is-link />
        <van-cell title="退出登录" @click="quit" is-link />
      </div>
    </div>
    <div v-if="passwordshow">
      <div style="width: 95%; margin: 10px 2.5%">
        <van-field v-model="ChangePwd.opwd" required label="旧密码" />
        <van-field
          v-model="ChangePwd.npwd"
          required
          type="password"
          label="新密码"
        />
        <van-field
          v-model="ChangePwd.npwds"
          required
          type="password"
          label="重复密码"
        />
      </div>
      <van-button
        @click="changePassword"
        round
        type="info"
        style="width: 80%; margin: 15px 10%"
        >提 交</van-button
      >
      <van-button @click="changes" round style="width: 80%; margin: 0px 10%"
        >返 回</van-button
      >
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
import { getaccToken, setaccToken, removeaccToken } from "@/utils/auth";
import { RE_GetMyEnterprise, ChangePwd } from "@/api/REplat";
export default {
  data() {
    return {
      ChangePwd: {
        opwd: "", //旧密码、
        npwd: "", //新密码
        npwds: "",
      },
      indexshow: true,
      passwordshow: false,
      list: [],
    };
  },
  created() {
    var token = getaccToken();
    if (token == undefined) {
      this.$router.push({
        name: "firmlogin",
      });
    }
    this.LoginSeeker();
  },
  methods: {
    LoginSeeker() {
      //获取当前登录用户
      RE_GetMyEnterprise({ accToken: getaccToken() }).then((res) => {
        console.log(res);
        this.list = res.data.data;
      });
    },
    quit() {
      //退出登录
      removeaccToken();
      this.$router.push({
        name: "list",
      });
    },
    change() {
      //修改密码显示
      this.indexshow = false;
      this.passwordshow = true;
    },
    changes() {
      this.indexshow = true;
      this.passwordshow = false;
    },
    changePassword() {
      //修改密码
      if (this.ChangePwd.opwd == "") {
        Toast.fail("旧密码不能为空!");
        return false;
      }
      if (this.ChangePwd.npwd == "") {
        Toast.fail("新密码不能为空!");
        return false;
      }
      if (this.ChangePwd.npwds == "") {
        Toast.fail("请再次输入密码!");
        return false;
      }
      if (this.ChangePwd.npwd != this.ChangePwd.npwds) {
        Toast.fail("两次密码不同!");
        return false;
      }
      this.ChangePwd.accToken = getaccToken();
      ChangePwd(this.ChangePwd).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          Toast.success("修改成功!");
          removeaccToken();
          this.$router.push({
            name: "firmlogin",
          });
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
  },
};
</script>
<style scoped>
.jobhunter {
  background: rgb(245, 242, 242);
  height: 100%;
}
.jobhunter .top {
  position: relative;
  width: 100%;
  /* height: 200px; */
  background: #188efb;
  /* border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px; */
}
.jobhunter .center {
  z-index: 10;
  position: relative;
  top: -30px;
  width: 80%;
  margin: 10px auto;
  background: #fff;
  border-radius: 15px;
  padding: 15px;
}
.top .names {
  position: absolute;
  top: 30px;
  width: 90%;
  margin: 0px 5%;
  overflow: hidden;
  font-size: 22px;
  color: #fff;
  text-align: center;
}
.blck_left {
  z-index: 5;
  position: absolute;
  left: 10px;
  top: 20px;
  font-size: 30px;
  color: #fff;
}
.blck_left .blcktitile {
  display: inline-block;
  width: 50px;
  position: absolute;
  top: 0px;
  font-size:22px;
  padding-left: 3px;
}
</style>